import React, {Component} from "react";
import recordStyle from './deliveryRecord.module.less'
import urgentImg from '../../assets/img/urgent.png'
import nothingImg from '../../assets/img/nothing.png'
import waitImg from '../../assets/img/home/wait.png'
import completeImg from '../../assets/img/home/complete.png'
import errorImg from '../../assets/img/home/error.png'
import PageLoading from '../speakLoading/pageLoading'
import {PositionApi} from "../../services/positionApi.js";
import filters from "../../utils/filters";

import { connect } from 'react-redux';
import {setTimerType, clearTimerType} from '../../store/actions'
let positionAi = new PositionApi();


class deliveryRecord extends Component {
    constructor(props) {
        console.log(props)
        super();
        this.state = {
            positionHotData: [],
            pageOption: {
                pageSize: 10,
                pageNo: 1,
                total: 0,
                pageCount: 0
            },
            params: {},
            // indCode:props.indCode,
            // mobile: "18600912106",               //  --------------临时为了测试改成 18600912106
            mobile:props.mobile,
            listLoading:true
        }
    }

    componentDidMount() {
        console.log(this.props,'nmnm')
        this.props.setTimerType("deliveryRecord");


        if(this.state.mobile){
            this.getList(this.state.mobile);
        }else{
            /* global my */
            if (navigator.userAgent.indexOf('AlipayClient') > -1) {
                my.postMessage({type:"recordCheckFace"});
            }
        }

        //监听扫脸结果
        window.addEventListener('recordCheckFace', this.recordCheckFace);
    }

    reloadData=()=>{
        if(this.state.mobile){
            this.getList(this.state.mobile);
        }else{
            /* global my */
            if (navigator.userAgent.indexOf('AlipayClient') > -1) {
                my.postMessage({type:"recordCheckFace"});
            }
        }
    }

    componentWillUnmount() {
        this.props.clearTimerType();
    }

    recordCheckFace=(res)=>{

        let userInfo = res.detail.data;

        // alert(userInfo.mobile);

        if(userInfo && userInfo.mobile){
            setTimeout(()=>{
                this.getList(userInfo.mobile);
            },500)
        }
    }

    getList=(mobile)=> {
        this.setState({
            listLoading:true
        })
        let obj={}
        obj.mobile = mobile
        // obj.mobile ='15884397585'
        // obj.mobile ='13198618958'
        obj.indCode = localStorage.getItem("IND_CODE");
        // alert(JSON.stringify(obj))
        if (!this.state.mobile){
            this.setState({
                mobile:obj.mobile
            })
        }
        this.setState({
            params:obj
        },()=>{
            console.log(this.state.params);
            if (obj.mobile==''){
                alert('未获取到用户信息')
            }else {
                positionAi.getDigitalMyHroAndCommunityRecommendList(obj).then(res=>{
                    if (res.data.code == 200) {
                        let arr=res.data.data[0].positionHroDistributeAndCommunityDtos

                        if(arr && arr.length > 0){
                            arr.map(item=>{
                                if(item.currentStage == 10 || item.currentStage == 15 || item.currentStage == 20 ){
                                    item.currentStageTxt = "简历待审核";
                                }else if( item.currentStage == 30){
                                    item.currentStageTxt = "待面试";
                                }else if(item.currentStage == 40){
                                    item.currentStageTxt = "面试通过";
                                }else if(item.currentStage == 50){
                                    item.currentStageTxt = "未通过";
                                }else if(item.currentStage == 60){
                                    item.currentStageTxt = "已离职";
                                }
                            })
                        }

                        this.setState({
                            positionHotData : arr,
                            pageOption:{
                                ...this.state.pageOption,
                                total:  res.data.data[0].count,
                                pageCount : res.data.data[0].pageCount == null ? 0 : res.data.data[0].pageCount
                            }
                        })
                        if (arr.length<=0){
                            this.props.onChildData(1);
                        }else {
                            this.props.onChildData(2);
                        }
                    }
                    setTimeout(()=>{
                        this.setState({
                            listLoading:false
                        })
                    },500)
                })
            }
        })

    }

    lastPage() {
        if (this.state.pageOption.pageNo !== 1) {
            let i=this.state.pageOption.pageNo-1
            this.setState({
                pageOption: {
                    ...this.state.pageOption,
                    pageNo:i
                }
            },()=>{
                this.getList()
            })
        }
    }

    nexPage() {
        if (this.state.pageOption.pageNo < this.state.pageOption.pageCount) {
            let i=this.state.pageOption.pageNo+1
            this.setState({
                pageOption: {
                    ...this.state.pageOption,
                    pageNo:i
                }
            },()=>{
                this.getList()
            })
        }
    }
    toDetail(e){
        this.props.onData(e);
    }
    toAiInterView(item){

        let positionId = item.employmentType === 3 ? item.positionId : item.positionHroId

        this.props.callParentMethod(positionId, item.employmentType,item.projectId,item.resumeId);
    }
    render() {
        return (
            <div className={recordStyle.deliveryRecord}>
                {/*<p>报名记录（{this.state.pageOption.total?this.state.pageOption.total:0}）</p>*/}
                <p></p>
                {
                    this.state.listLoading?(
                        <div className={recordStyle.loading}>
                            <PageLoading></PageLoading>
                        </div>
                    ):(
                        this.state.positionHotData.length > 0 ? (
                            <div className={recordStyle.content}>
                                <div className={recordStyle.listScroll}>
                                    <div className={recordStyle.list}>
                                        {
                                            this.state.positionHotData.map((item ,index)=> {
                                                return(
                                                    <div className={recordStyle.listContent} key={index} >
                                                        <div className={recordStyle.listConTitle}>
                                                            <div className={recordStyle.positionDetail}>
                                                                <div className={recordStyle.topTitle}>
                                                                    <h2 className={recordStyle.positionName}>{item.countyName} · {item.positionName}</h2>
                                                                    {
                                                                        item.isUrgent == 1 ? (
                                                                            <img src={urgentImg} className={recordStyle.urgent} alt=""/>
                                                                        ) : ('')
                                                                    }
                                                                </div>
                                                                <div className={recordStyle.btn}>
                                                                    {
                                                                        item.currentStage == 10 || item.currentStage == 15 || item.currentStage == 20|| item.currentStage == 30?(
                                                                            <p className={`${recordStyle.btnStatus} ${recordStyle.btnOne}`}>
                                                                                {/*<img src={waitImg} alt=""/>*/}
                                                                                {item.currentStageTxt}
                                                                            </p>
                                                                        ):('')
                                                                    }
                                                                    {
                                                                        item.currentStage==50||item.currentStage==60?(
                                                                            <p  className={`${recordStyle.btnStatus} ${recordStyle.btnTwo}`}>
                                                                                {/*<img src={errorImg} alt=""/>*/}
                                                                                {item.currentStageTxt}
                                                                            </p>
                                                                        ):('')
                                                                    }
                                                                    {
                                                                        item.currentStage==40?(
                                                                            <p  className={`${recordStyle.btnStatus} ${recordStyle.btnThree}`}>
                                                                                {/*<img src={completeImg} alt=""/>*/}
                                                                                {item.currentStageTxt}
                                                                            </p>
                                                                        ):('')
                                                                    }

                                                                </div>
                                                            </div>
                                                            <aside className={recordStyle.bottomDetail}>
                                                                <section className={recordStyle.company}>
                                                                    <div className={`${recordStyle.typeTxt} ${recordStyle.typeTxt1}`}>
                                                                        {
                                                                            item.employmentType==3 || item.employmentType==4?(
                                                                                <p className={recordStyle.strContent}>{item.ceInfoDto.ceName ? item.ceInfoDto.ceName : '未填写'}</p>
                                                                            ):(
                                                                                <p className={recordStyle.strContent}>{item.enterpriseName ? item.enterpriseName : '未填写'}</p>
                                                                            )
                                                                        }
                                                                        {
                                                                            item.positionType == 0 ? (
                                                                                <p className={recordStyle.strContent}>| 全职</p>
                                                                            ) : ('')
                                                                        }
                                                                        {
                                                                            item.positionType == 1 ? (
                                                                                <p className={recordStyle.strContent}>| 兼职</p>
                                                                            ) : ('')
                                                                        }
                                                                        {
                                                                            item.positionType == 2 ? (
                                                                                <p className={recordStyle.strContent}>| 零工</p>
                                                                            ) : ('')
                                                                        }
                                                                        {
                                                                            item.positionType == 3 ? (
                                                                                <p className={recordStyle.strContent}>| 实习</p>
                                                                            ) : ('')
                                                                        }
                                                                        {
                                                                            item.positionNature == 0 ? (
                                                                                <p className={recordStyle.strContent}>| 其他</p>
                                                                            ) : ('')
                                                                        }
                                                                        {
                                                                            item.positionNature == 1 ? (
                                                                                <p className={recordStyle.strContent}>| 全职</p>
                                                                            ) : ('')
                                                                        }
                                                                        {
                                                                            item.positionNature == 2 ? (
                                                                                <p className={recordStyle.strContent}>| 兼职</p>
                                                                            ) : ('')
                                                                        }
                                                                        {
                                                                            item.positionNature == 3 ? (
                                                                                <p className={recordStyle.strContent}>| 零工</p>
                                                                            ) : ('')
                                                                        }
                                                                        {
                                                                            item.positionNature == 4 ? (
                                                                                <p className={recordStyle.strContent}>| 实习</p>
                                                                            ) : ('')
                                                                        }
                                                                        <p className={recordStyle.strContent}>
                                                                            | {item.digEducationStr == '不限' ? '学历' + item.digEducationStr : item.digEducationStr}
                                                                        </p>
                                                                        <p className={recordStyle.strContent}>
                                                                            | {item.workingLifeStr?item.workingLifeStr:'经验不限'}
                                                                        </p>
                                                                    </div>
                                                                </section>
                                                            </aside>
                                                            <div className={recordStyle.itemBottom}>
                                                                <div className={recordStyle.salary}>
                                                                    {
                                                                        item.employmentType == 3 && item.positionType == 0 && item.minSalary && item.maxSalary ? (
                                                                            <b className={recordStyle.salaryItem}>
                                                                                {item.minSalary}-{item.maxSalary}元·{item.salaryMonths?item.salaryMonths:12}薪
                                                                            </b>
                                                                        ) : ('')
                                                                    }
                                                                    {
                                                                        item.employmentType == 3 && item.positionType == 0 && item.discussPersonally == 1 ? (
                                                                            <b className={recordStyle.salaryItem}>面议</b>
                                                                        ) : ('')
                                                                    }
                                                                    {
                                                                        item.employmentType == 3 && item.positionType !== 0 && item.partTimeSalary ? (
                                                                            <b className={recordStyle.salaryItem}>
                                                                                {item.partTimeSalary} {item.partTimeSalaryTypeText}
                                                                            </b>
                                                                        ) : ('')
                                                                    }
                                                                    {
                                                                        item.employmentType != 3 && item.minSalary && item.maxSalary ? (
                                                                            <b className={recordStyle.salaryItem}>
                                                                                {item.minSalary}-{item.maxSalary}元/月/人
                                                                            </b>
                                                                        ) : ('')
                                                                    }

                                                                </div>
                                                                <p className={recordStyle.time}>{filters.getDateShowText(item.deliverTime,'yyyy.MM.dd hh:mm')}</p>
                                                            </div>


                                                        </div>
                                                        <div className={recordStyle.listCon_address}>
                                                            {
                                                                item.interviewStatus === 0 ? (
                                                                    <button className={recordStyle.btnActive} onClick={()=>this.toAiInterView(item)}>AI面试</button>
                                                                ) : (
                                                                    <button className={recordStyle.btnActive} onClick={()=>this.toDetail(item)}>查看面试指导</button>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className={recordStyle.blockHeight}></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={recordStyle.list}>
                                <div className={recordStyle.notList}>
                                    <img src={nothingImg} alt=""/>
                                    <p>暂无报名记录</p>
                                    <span>去找份合适的职位报名吧！</span>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => ({
    setTimerType: (timerType) => {
        console.log(timerType,'opolp')
        dispatch(setTimerType(timerType))
    },
    clearTimerType: () => dispatch(clearTimerType())
});

export default connect(null, mapDispatchToProps,null,{ forwardRef: true } )(deliveryRecord);
// export default deliveryRecord;