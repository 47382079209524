let common = {};









common.formatDate = (inputDateStr, formatStr) =>{

    if(formatStr.indexOf('hh') >= 0){
        formatStr = formatStr.replace("hh","HH")
    }

    // 将 '+0000' 替换为 'Z'，以确保正确解析为 UTC 时间
    const dateStr = inputDateStr.replace('+0000', 'Z');
    const date = new Date(dateStr);

    // 检查日期是否有效
    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }

    // 提取日期各部分并格式化为两位数
    const replacements = {
        yyyy: date.getFullYear(),
        MM: String(date.getMonth() + 1).padStart(2, '0'), // 月份从 0 开始
        dd: String(date.getDate()).padStart(2, '0'),
        HH: String(date.getHours()).padStart(2, '0'),
        mm: String(date.getMinutes()).padStart(2, '0'),
        ss: String(date.getSeconds()).padStart(2, '0'),
    };

    // 替换格式字符串中的标识符
    let formattedDate = formatStr;
    for (const key in replacements) {
        formattedDate = formattedDate.replace(key, replacements[key]);
    }

    return formattedDate;
}

common.isEmpty = (value) => {
    if(value === undefined || value === null || value === ''){
        return true;
    }

    return false;
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}





common.isEmptyArray = (value) => {
    if(value === undefined || value === null || value.length == 0){
        return true;
    }

    return false;
}


common.checkEmpty = (value) => {
    if(value == -1){
        return '';
    }

    return value;
}

common.hasPermi = (value, params) => {
    let permissions = params.map((item) => Number(item.id));
    if (value && !permissions.includes(8)) {
        if (!permissions.includes(value)) {
            return false;
        }else {
            return true;
        }
    }else {
        return true;
    }
}

export default common;
