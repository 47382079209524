// 企业直招
import React, { useState, useEffect, useRef } from 'react';
import style from './interviewReport.module.less'
import hotIcon from '../../assets/img/urgent.png';
import filters from '../../utils/filters.js';
import '../../assets/iconfont/iconfont.css'
import defaultIcon from '../../assets/img/defaultIcon.png'
import {AiApi} from "../../services/aiApi.js";
import PageLoading from '../speakLoading/pageLoading'

import { useDispatch } from 'react-redux';
import {setTimerType, clearTimerType} from '../../store/actions'
import { CSSTransition } from 'react-transition-group';
import '../test/test.css';
let aiApi = new AiApi();

const InterviewReport = ({reportData,backReport}) => {

    const key = reportData.interviewKey;
    const [loading, setLoading] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [showCon, setShow] = useState(false);

    const dispatch = useDispatch();

    let map = null;

    useEffect(() => {

        return () => {

        }
    }, []);

    useEffect(() => {
        getGuidance();
    }, [key]);

    const getGuidance = () => {
        setLoading(true);
        let params = {
            key: key
        }
        aiApi.get_guidance(params).then((res) => {
            if(res.data.data) {
                let obj=res.data.data
                setQuestionList(obj["面试问题"]);

                console.log(obj["面试问题"]);
            }
            setLoading(false);
            setTimeout(()=>{
                setShow(!showCon)
            },500)
        })
    }

    return (
        <div className={style.positionWrap}>
            {
                loading ? (
                    <div className={style.loading}>
                        <PageLoading></PageLoading>
                    </div>
                ) : (
                    <div className={style.detailHeight}>
                        <CSSTransition in={showCon} timeout={300} classNames={{
                            enter: "alert-enter",
                            enterActive: "alert-enter-active",
                            exit: "alert-exit",
                            exitActive: "alert-exit-active",
                        }} unmountOnExit>
                            <div className={style.detail}>

                                {/*style={{height: elementHeight ? elementHeight : ''}}*/}

                                <div className={style.conHeight} >

                                    <div className={style.sectionCon}>
                                        <article>
                                            <div className={style.conTitle_left}>
                                                <h1>面试答案指导</h1>
                                            </div>
                                        </article>

                                        <div className={style.conHeight} >
                                            { questionList.map((item, index) => (
                                            <div className={style.sectionCon} key={index}>
                                                <article>
                                                    <h4>问题: {item["问题"]}</h4>
                                                </article>
                                                <article>

                                                    <div style={{overflow: 'hidden'}}>
                                                        <div className={style.homeDetail}>
                                                            <div className={style.item}>
                                                                <div className={style.itemLeft}>面试回答：</div>
                                                                <div className={style.itemRight}>
                                                                    {item["面试回答"]}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div style={{overflow: 'hidden'}}>
                                                        <div className={style.homeDetail}>
                                                            <div className={style.item}>
                                                                <div className={style.itemLeft}>点评：</div>
                                                                <div className={style.itemRight}>
                                                                    {item["点评"]}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div style={{overflow: 'hidden'}}>
                                                        <div className={style.homeDetail}>
                                                            <div className={style.item}>
                                                                <div className={style.itemLeft}>优化建议：</div>
                                                                <div className={style.itemRight}>
                                                                    {item["优化建议"]}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div style={{overflow: 'hidden'}}>
                                                        <div className={style.homeDetail}>
                                                            <div className={style.item}>
                                                                <div className={style.itemLeft}>最佳答案：</div>
                                                                <div className={style.itemRight}>
                                                                    {item["最佳答案"]}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </article>

                                            </div>))
                                            }
                                            <div className={style.blockHeight}></div>
                                        </div>
                                    </div>
                                    <div className={style.blockHeight}></div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                )
            }
        </div>
    );
};

export default InterviewReport;