import { get,post } from '../common/request.js';
const sgApi = "/api/"
const digCApi = "/digCApi/"
const sgcApi = "/sgApi/"
class AiApi {
    createDigitalInterviewQr(params){
        let url = sgApi + "system/api/sangu/createDigitalInterviewQr";
        return get(url,params);
    }
    voiceToText(params){
        let url = sgApi + "system/api/sangu/voiceToText";
        return post(url,params);
    }
    //AI获取结果
    get_aiInterview_result(params) {
        let url = sgApi + "system/api/sangu/get_aiInterview_result";
        return get(url,params);
    }

    //创建面试题
    create_questions(params) {
        let url = sgcApi + "position/api/position/createAiInterviewQuestions";
        return post(url,params);
    }

    //AI获取结果
    get_questions(params) {
        let url = digCApi + "digital_interview/get_questions";
        return get(url,params);
    }

    //保存答案
    answer(params) {
        let url = digCApi + "digital_interview/answer";
        return post(url,params);
    }

    create_report(params) {
        let url = digCApi + "digital_interview/create_report";
        return post(url,params);
    }

    get_guidance(params) {
        let url = digCApi + "digital_interview/get_guidance";
        return get(url,params);
    }

    set_hro_interview_status(params) {
        let url = sgcApi + "project/api/project/setAiInterviewStatus";
        return get(url,params);
    }

    set_comm_interview_status(params) {
        let url = sgcApi + "project/api/community/project/setAiInterviewStatus";
        return get(url,params);
    }

}
export  {
    AiApi
};